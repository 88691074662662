<template>
  <div class="iot-history fadeIn">
    <van-nav-bar
      title="历史数据"
      left-text="返回"
      left-arrow
      @click-left="$router.go(-1)"
    />
    <!-- <van-popup
      v-model="setParameter"
      position="left"
      :style="{ width: '80%', height: '100%' }"
      style="z-index: 1800 !important"
    >
      <div class="popup-form">
        <el-form ref="form" label-width="80px">
          <el-form-item label="日期：">
            <el-date-picker
              size="mini"
              v-model="historyFrom.date"
              type="date"
              value-format="yyyy-MM-dd"
              :picker-options="pickerOptions"
              @change="paramChange"
              placeholder="选择日期"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="参数：">
            <el-select
              multiple
              collapse-tags
              :multiple-limit="5"
              v-model="historyFrom.paramId"
              placeholder="请选择"
              @blur="paramChange"
            >
              <el-option
                v-for="item in paramList"
                :key="
                  item.paramId + '/' + item.gatewayId + '/' + item.subOriginalId
                "
                :label="item.paramName"
                :value="
                  item.paramId + '/' + item.gatewayId + '/' + item.subOriginalId
                "
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </div>
    </van-popup> -->
    <div class="historyTitle">
      <el-form :inline="true" :model="historyFrom" class="iot-form-inline">
        <el-form-item style="width: 35%; height: 40px; margin-right: 2px">
          <el-date-picker
            size="mini"
            :clearable="false"
            v-model="historyFrom.date"
            type="date"
            @change="paramChange"
            value-format="yyyy-MM-dd"
            :picker-options="pickerOptions"
            placeholder="选择日期"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item style="width: 60%">
          <el-select
            size="mini"
            multiple
            collapse-tags
            :multiple-limit="5"
            v-model="historyFrom.paramId"
            placeholder="请选择"
            @blur="paramChange"
          >
            <el-option
              v-for="item in paramList"
              :key="
                item.paramId + '/' + item.gatewayId + '/' + item.subOriginalId
              "
              :label="item.paramName"
              :value="
                item.paramId + '/' + item.gatewayId + '/' + item.subOriginalId
              "
            >
            </el-option>
          </el-select>
        </el-form-item>
        <!-- <el-form-item style="width:15%">
                <el-button
                  size="mini"
                  type="success"
                  icon="el-icon-search"
                  @click="paramChange"
                ></el-button>
              </el-form-item> -->
      </el-form>
    </div>
    <div class="iot-history-title">
      <div style="margin-right: 50px; border: none; padding: 0">
        <div style="display: inline-block">
          <div class="switch">
            <div>
              <div class="ysList">
                <img
                  @click="checkHistory = true"
                  src="../../public/backImg/historyLogo.png"
                />
              </div>
            </div>
            <div>
              <div class="ysList">
                <img
                  @click="checkHistory = false"
                  src="../../public/backImg/formLogo.png"
                  alt=""
                />
              </div>
            </div>
            <div>
              <div class="ysList">
                <img
                  @click="downloadData"
                  src="../../public/backImg/download.png"
                  alt=""
                />
              </div>
            </div>
            <div style="margin: 2px 5px">
              <div class="ysList">
                <img @click="setParam" src="../../public/backImg/set.png" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 历史曲线 -->
    <div class="iot-history-data" v-show="checkHistory">
      <div id="historyData" ref="historyData"></div>
    </div>
    <!-- 数据列表 -->
    <div class="iot-history-list" v-show="!checkHistory">
      <div
        style="width: 240px; flex-grow: 0; margin: 0 0px"
        v-for="(item, index) in historyData"
        :key="index"
      >
        <el-table :data="item.data" :max-height="maxHeight">
          <el-table-column :label="item.name" width="200">
            <el-table-column prop="0" label="采集时间" width="100">
            </el-table-column>
            <el-table-column prop="1" label="数值" width="100">
            </el-table-column>
          </el-table-column>
        </el-table>
      </div>
    </div>
  </div>
</template>
<script>
let that;
import * as echarts from "echarts";
export default {
  name: "History",
  data() {
    return {
      maxHeight: window.innerHeight - 150 + "px",
      checkBtn: "数据表格",
      checkHistory: true,
      setParameter: false,
      historyData: [],
      historyDate: "",
      projectGroupChangeArr: "",
      selectUserId: "",
      groupList: [],
      projectGroupId: "",
      projectList: [],
      paramList: [],
      historyFrom: {
        projectId: this.$route.query.projectId,
        paramId: [],
        date: "",
      },
      //设置时间
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
      },
      getHistory(data, date, ref) {
        let option = {
          tooltip: {
            trigger: "axis",
          },
          toolbox: {
            right: 10,
            top: 10,
          },
          legend: {
            bottom: "1%",
            left: "10%",
            right: "10%",
            type: "plain",
            orient: "horizontal",
          },
          grid: {
            y: 50,
            y2: 80,
            x: 50,
            x2: 50,
          },
          xAxis: {
            type: "category",
            boundaryGap: false,
            data: date,
          },
          yAxis: {
            type: "value",
            nameLocation: "end",
            boundaryGap: [0, 0],
          },
          dataZoom: [
            {
              type: "inside",
              start: 0,
              end: 100,
            },
          ],
          series: data,
        };
        var chartDom = document.getElementById(ref);
        let myChart = echarts.init(chartDom);
        myChart.clear();
        myChart.setOption(option);
      },
    };
  },
  methods: {
    setParam() {
      that.setParameter = true;
    },
    //获取非布尔参数列表
    getParamList(id) {
      that.$api.original.getParamList(id).then((res) => {
        if (res.code == 200) {
          that.paramList = res.data;
          that.historyFrom.paramId = [
            that.paramList[0].paramId +
              "/" +
              that.paramList[0].gatewayId +
              "/" +
              that.paramList[0].subOriginalId,
          ];
          that.paramChange();
        }
      });
    },
    paramChange1(value) {
      if (value) {
        return;
      } else {
        that.paramChange();
      }
    },
    paramChange() {
      if (that.historyFrom.paramId.length > 0) {
        let arr = [];

        that.paramList.forEach((item) => {
          that.historyFrom.paramId.forEach((ite) => {
            if (
              item.paramId == ite.split("/")[0] &&
              item.gatewayId == ite.split("/")[1] &&
              item.subOriginalId == ite.split("/")[2]
            ) {
              arr.push(item.paramId);
            }
          });
        });

        let data = {
          date: that.historyFrom.date,
          projectId: that.historyFrom.projectId,
          ctrls: [
            {
              ctrlId: 0,
              pmIds: arr,
            },
          ],
        };
        that.getHistoryData(data);
      }
    },
    //获取历史曲线数据
    getHistoryData(data) {
      that.$api.largeDataScreen.getHistoryData(data).then((res) => {
        if (res.code == 200) {
          that.historyData = res.data[0].data;
          that.historyDate = res.data[0].date;
          that.setHisturyBody();
        }
      });
    },
    setHisturyBody() {
      //   if (that.checkHistory) {
      that.setHistory();
      //   }
    },
    setHistory() {
      let arr = [];

      this.historyData.forEach((element) => {
        arr.push({
          name: element.name,
          type: "line",
          smooth: true,
          symbol: "none",
          data: element.stream,
        });
      });
      that.getHistory(arr, that.historyDate, "historyData");
    },
    // 默认获取当前时间
    getDate() {
      let date = new Date();
      let month =
        date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1;
      let dates = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();

      that.historyFrom.date = date.getFullYear() + "-" + month + "-" + dates;
    },
    //历史数据下载
    downloadData() {
      if (that.historyFrom.paramId.length > 0) {
        let arr = [];

        that.paramList.forEach((item) => {
          that.historyFrom.paramId.forEach((ite) => {
            if (
              item.paramId == ite.split("/")[0] &&
              item.gatewayId == ite.split("/")[1] &&
              item.subOriginalId == ite.split("/")[2]
            ) {
              arr.push({
                gatewayId: item.gatewayId,
                subOriginalId: item.subOriginalId,
                paramId: item.paramId,
              });
            }
          });
        });

        let data = {
          projectId: that.historyFrom.projectId,
          date: that.historyFrom.date,
          vos: arr,
        };
        that.$api.project.downloadData(data).then((res) => {
          if (res.code == 200) {
            window.open(res.data);
          }
        });
      }
    },
  },
  created() {
    that = this;
  },
  mounted() {
    -that.getParamList(that.$route.query.planId);
    that.getDate();
  },
};
</script>
<style scoped>
.iot-history {
  width: 100%;
  height: 100%;
}
#historyData {
  width: 100vw;
  height: 100vw;
}
.popup-form {
  width: 100%;
  height: 100%;
  padding: 10px 8px;
  box-sizing: border-box;
  background-color: #fff;
  overflow-x: hidden;
}
.popup-form > div {
  border-radius: 12px;
  margin: 10px 0;
}
.el-date-editor {
  width: 100% !important;
  height: 48px !important;
}

.iot-history-title {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin: 10px 0;
}
.iot-history-title > div {
  padding: 5px 10px;
  font-size: 14px;
  border: 1px solid #999;
  border-radius: 5px;
}

.iot-history-list {
  overflow: auto;
  display: flex;
  flex-wrap: nowrap;
}
.el-form-item {
  background-color: #fff;
}

.switch {
  display: flex;
}
.switch > div {
  margin: 2px 5px;
}
::v-deep .cell {
  text-align: center !important;
}
.historyTitle {
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  position: relative;
}
::v-deep .el-input__icon {
  height: 40px;
}
.el-form-item {
  margin: 0;
}
</style>
